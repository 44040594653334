var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1450px","persistent":"","scrollable":"","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.permissionCan('create'))?_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.$emit('new')}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("MENU.NEW"))+" "+_vm._s(_vm.$t("MENU.PROPERTIES"))+" ")]):_vm._e()]}}]),model:{value:(_vm.modalData.dialog),callback:function ($$v) {_vm.$set(_vm.modalData, "dialog", $$v)},expression:"modalData.dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle)+" ")]),_c('v-btn',{staticClass:"close-button",attrs:{"icon":""},on:{"click":_vm.handleCloseModalForm}},[_c('v-icon',[_vm._v(" mdi-close")])],1)],1)],1)],1),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-select',{attrs:{"items":_vm.languages,"disabled":_vm.languages.length == 1},scopedSlots:_vm._u([{key:"selection",fn:function(slotProps){return [_c('span',{staticClass:"symbol symbol-20 mr-3"},[_c('img',{attrs:{"src":_vm.selectedLocale.flag,"alt":""}})]),_vm._v(" "+_vm._s(slotProps.item.name)+" ")]}},{key:"item",fn:function(slotProps){return [_c('span',{staticClass:"symbol symbol-20 mr-3"},[_c('img',{attrs:{"src":slotProps.item.flag,"alt":""}})]),_c('span',{staticClass:"navi-text"},[_vm._v(_vm._s(slotProps.item.name))])]}}]),model:{value:(_vm.selectedLocale),callback:function ($$v) {_vm.selectedLocale=$$v},expression:"selectedLocale"}}),(_vm.formModel && _vm.formModel.custom_fields)?_c('CustomFieldComponent',{attrs:{"config":{
                type: 'multi_checkbox',
                label: 'Multi select',
                items: [
                  { label: 'Több választási lehetőség', value: 'true' } ],
              },"customFieldName":"multiCHK","value":_vm.formModel.custom_fields.multiCHK || []},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e(),(_vm.formModel && _vm.formModel.custom_fields)?_c('CustomFieldComponent',{attrs:{"config":{
                type: 'multi_checkbox',
                label: 'Szám érték',
                items: [{ label: 'Szám érték', value: 'true' }],
              },"customFieldName":"isNumber","value":_vm.formModel.custom_fields.isNumber || []},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[(_vm.formModel.translations[_vm.selectedLocale.lang])?_c('v-text-field',{attrs:{"rules":_vm.nameRules,"label":_vm.$t('FORMS.name'),"id":_vm.dynamicID,"error-messages":_vm.messages['translations.' + _vm.selectedLocale.lang + '.name']},on:{"keyup":function($event){_vm.messages['translations.' + _vm.selectedLocale.lang + '.name'] = ''}},model:{value:(_vm.formModel.translations[_vm.selectedLocale.lang].name),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang], "name", $$v)},expression:"formModel.translations[selectedLocale.lang].name"}}):_vm._e()],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[(
                _vm.formModel.translations[_vm.selectedLocale.lang] &&
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
              )?_c('CustomFieldComponent',{attrs:{"config":{
                type: 'text',
                label: _vm.$t('FORM_INPUT_NAMES.quantity_unit'),
              },"customFieldName":"quantityUnit","value":_vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  .quantityUnit},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1)],1),_c('v-form',{ref:"formProperty",attrs:{"lazy-validation":""},model:{value:(_vm.formPropertyValid),callback:function ($$v) {_vm.formPropertyValid=$$v},expression:"formPropertyValid"}},[_c('v-row',[(
                _vm.inputData.translations &&
                _vm.inputData.translations[_vm.selectedLocale.lang]
              )?_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_vm._l((_vm.languages),function(language,index){return _c('v-text-field',{key:("language-" + index),attrs:{"label":_vm.$t('FORMS.name') + ' (' + language.lang + ')',"rules":[
                  function (value) {
                    return _vm.genericRequiredRule(
                      value,
                      'Csak szám lehet a mező értéke'
                    );
                  } ]},model:{value:(_vm.inputData.translations[language.lang]['name']),callback:function ($$v) {_vm.$set(_vm.inputData.translations[language.lang], 'name', $$v)},expression:"inputData.translations[language.lang]['name']"}})}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.handleAddInputData}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.add"))+" ")])],2):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"8","md":"8"}},[_c('DeleteModalDialog',{attrs:{"dialogDelete":_vm.dialogDelete},on:{"closeDelete":_vm.handleCloseDelete,"deleteItemConfirm":_vm.handleDeleteItemConfirm}}),_c('v-data-table',{directives:[{name:"sortable-data-table",rawName:"v-sortable-data-table"}],attrs:{"headers":_vm.headers,"items":_vm.values,"search":_vm.search},on:{"sorted":_vm.saveOrder,"click:row":_vm.handleClickItem},scopedSlots:_vm._u([{key:"item.translated",fn:function(ref){
                var item = ref.item;
return [(item.translated)?_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("check_circle")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [(_vm.permissionCan('delete'))?_c('v-icon',{attrs:{"color":"primary","small":"","disabled":_vm.selectedItemIndex},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}])})],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"mb-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.handleCloseModalForm}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.cancel"))+" ")]),(
          (_vm.permissionCan('create') && !_vm.formModel.id) ||
          _vm.permissionCan('update')
        )?_c('v-btn',{attrs:{"color":"primary","elevation":"2","disabled":_vm.formModel.values.length == 0},on:{"click":_vm.handleSaveModalForm}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.save"))+" ")]):_vm._e()],1)],1),_c('v-overlay',{attrs:{"value":_vm.loader}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }